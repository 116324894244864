@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inclusive+Sans:ital@0;1&family=Open+Sans&family=Oxygen&family=Poppins:wght@200;300;400;600;800&family=Roboto+Condensed&family=Roboto+Mono:wght@500&family=Roboto:ital,wght@0,400;0,500;1,300;1,400&family=Ruda:wght@500;600;700;800&family=Ubuntu:ital,wght@0,400;0,500;1,400;1,500&display=swap");

html,
body {
  font-family: "Ubuntu", sans-serif;
  scroll-behavior: smooth;
  background-color: #ffff;
}
